<template>
  <div>
    <div class="d-flex flex-wrap border-bottom mb-1">
      <div class="my-3 mr-auto">
        <h5 class="text-black mt-2 mb-3">
          <strong>Dokumenter</strong>
        </h5>
      </div>
      <a-upload class="btn btn-light text-blue my-3 mr-3"
        :show-upload-list="false"
        :action="uploadFile"
        :before-upload="beforeUpload"
        @change="handleChange">Last opp ny dokument</a-upload>
      <a-modal
        title="Bekreft Overskriving"
        :visible="duplicateUpload"
        @ok="confirmDuplicateUpload"
        @cancel="duplicateUpload = false"
      >
        <p>En fil med dette navnet "{{ duplicateFile.name }}" finnes allerede. OK for å overskrive.</p>
      </a-modal>
      <!-- <a class="btn btn-light text-blue my-3 mr-3" href="javascript: void(0);">Create New Folder</a> -->
    </div>
    <!-- need to handle drag&drop refresh & folders -->
    <!-- <div class="border-bottom mb-3 pb-1">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <a-icon type="home" />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <a-icon type="user" />
          <span>Application List</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          Application
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div> -->
    <div class="table-responsive" v-if="loaded">
      <table class="table table-hover text-nowrap">
        <tbody>
          <tr v-for="(item, index) in data" :key="index">
            <td style="width: 25px;">
              <i :class="[item.type === 'folder' ? 'fa-folder' : 'fa-file-o', 'fa']" />
            </td>
            <td class="text-nowrap" v-if="item.type === 'folder'">
              <a @click.prevent="changeFolder(item.name)"
              >{{item.name}}</a>
            </td>
            <td class="text-nowrap" v-else>
              <a :href="item.url"
              target="_blank"
              >{{item.name}}</a>
            </td>
            <td class="text-nowrap">{{item.info}}</td>
            <td class="text-nowrap text-right">{{item.time}}</td>
            <td v-if="!isUser">
              <a-popconfirm
                :title="'Er du sikker du ønsker å slette '.concat(item.name)"
                ok-text="Ja"
                cancel-text="Nei"
                @confirm="deleteFile(item.name)"
              >
                <a href="#"><i class="fa fa-trash" /></a>
              </a-popconfirm>
            </td>
            <td v-if="!isUser"><a-switch :default-checked="item.visibleToEmployee" @click="lastEvent = item.name" @change="changeVisibility" :loading="loadingChange" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="d-flex">
      <a-spin class="mr-auto ml-auto mt-3">
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      </a-spin>
    </div>
  </div>
</template>
<script>
import * as firebase from 'firebase/app'
import 'firebase/storage'
import moment from 'moment'
import { openNotification } from '@/services/powerAutomate'

const storageRef = firebase.storage().ref('/users')
let listRef

export default {
  props: ['reference'],
  data() {
    return {
      data: [],
      duplicateUpload: false,
      duplicateFile: {},
      lastEvent: '',
      loadingChange: false,
      isUser: false,
      loaded: false,
      subFolder: '',
    }
  },
  async mounted() {
    listRef = storageRef.child(String(this.reference))
    this.isUser = Boolean(this.$store.state.user.employeeID === this.reference && this.$store.state.user.role !== 'admin')
    this.refreshList()
  },
  methods: {
    moment,
    refreshList() {
      console.log(this.isUser)
      this.data = []
      this.loaded = false
      listRef.listAll()
        .then((res) => {
          console.log(res)
          res.prefixes.forEach((folderRef) => {
            console.log(folderRef)
            const folder = {
              type: 'folder',
              name: folderRef.name,
              time: String(moment(folderRef.updated).calendar()),
              visibleToEmployee: true,
            }
            this.data.push(folder)
          })
          res.items.forEach((itemRef) => {
            let meta
            itemRef.getMetadata().then(metadata => {
              meta = metadata
              return itemRef.getDownloadURL()
            }).then(url => {
              const visible = Boolean(meta.customMetadata && meta.customMetadata.visibleToEmployee)
              if (!this.isUser || visible) {
                const file = {
                  type: meta.contentType,
                  name: meta.name,
                  time: String(moment(meta.updated).calendar()),
                  url: url,
                  visibleToEmployee: visible,
                }
                this.data.push(file)
              }
            })
          })
        }).catch((error) => {
          console.log(error)
        }).finally(() => {
          this.loaded = true
        })
    },
    changeFolder(child) {
      listRef = listRef.child(child.concat('/'))
      this.refreshList()
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loaded = false
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        this.loaded = true
      }
    },
    beforeUpload(file) {
      const duplicate = this.data.some(item => item.name === file.name)
      if (duplicate && !this.isUser) {
        this.duplicateFile = file
        this.duplicateUpload = true
      } else if (duplicate && this.isUser) {
        openNotification('Du har ikke rettigheter for å overskrive filer')
      }
      return !duplicate
    },
    uploadFile(file) {
      // need to handle duplicate names. best to check existing listRef
      listRef.child(file.name).put(file).then((snapshot) => {
        return snapshot.ref.getDownloadURL()
      }).then(url => {
        console.log(url)
        this.refreshList()
      }).catch(err => {
        console.log(err)
      })
    },
    deleteFile(i) {
      console.log(i)
      listRef.child(i).delete()
        .then(() => {
          this.refreshList()
        })
    },
    confirmDuplicateUpload() {
      this.duplicateUpload = false
      this.uploadFile(this.duplicateFile)
    },
    async changeVisibility(visible) {
      await setTimeout(console.log('c'), 100)
      listRef.child(this.lastEvent).updateMetadata({
        customMetadata: {
          visibleToEmployee: visible,
        },
      }).then(meta => {
        console.log(meta)
      })
      console.log(visible, this.lastEvent)
    },
  },
}
</script>
