<template>
    <div>
      <document-area :reference="$store.state.user.employeeID" />
    </div>
</template>

<script>
import documentArea from '@/components/custom/user/documents'

export default {
  components: {
    documentArea,
  },
}
</script>
